import { memo, useMemo, useEffect, useState, useCallback, Text } from "react";
import {
	Grid,
	Typography,
	Link as MaterialLink,
	Box,
	ToggleButtonGroup,
	ToggleButton,
	Checkbox,
	TextField,
	FormControlLabel,
	FormControl,
	InputLabel,
	MenuItem
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Edit, Archive, Delete, Close, ConstructionOutlined, Info } from "@mui/icons-material";
import ReportIcon from '@mui/icons-material/Report';
import CheckIcon from '@mui/icons-material/Check';
import VerifiedIcon from '@mui/icons-material/Verified';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, useNavigate } from "react-router-dom";
import { dateOldToNew, stringAToZInsensitive } from "@iamnapo/sort";
import Image from "mui-image";

import { getAnnouncements, getAllAnnouncements, deleteAnnouncement, getFeedbacks, deleteFeedback, resolveFeedback } from "../api";
import Table from "../components/Table";
import { isFuzzyMatch, dayjs, useSnackbar, counties } from "../utils";

import {
	SecondaryBackgroundButton,
	PrimaryBackgroundButton,
	PrimaryBorderButton
} from "../components/Buttons";
import { newAnnouncement } from "../api";
import Popup from "../components/Popup";

const Home = () => {
	let navigate = useNavigate();

	const [announcements, setAnnouncements] = useState([]);
	const [announcement, setAnnouncement] = useState(null);
	const [feeds, setFeeds] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [viewPopupOpen, setViewPopupOpen] = useState(false);
	const { info, error } = useSnackbar();

	// New announcement info
	const [newPopupOpen, setNewPopupOpen] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [county, setCounty] = useState("*");
	const [imageUrl, setImageUrl] = useState("");
	const [url, setUrl] = useState("");
	const [pushNotification, setPushNotification] = useState(false);
	const [expirationDate, setExpirationDate] = useState(new Date());

	const [titlePopupOpen, setTitlePopupOpen] = useState(false);
	const [descriptionPopupOpen, setDescriptionPopupOpen] = useState(false);
	const [imagePopupOpen, setImagePopupOpen] = useState(false);
	const [urlPopupOpen, setUrlPopupOpen] = useState(false);
	const [countiesPopupOpen, setCountiesPopupOpen] = useState(false);
	const [datePopupOpen, setDatePopupOpen] = useState(false);
	const [pushPopupOpen, setPushPopupOpen] = useState(false);

	useEffect(() => { }, [announcements, announcement]); // Just to refresh the page when announcements change

	const fetchAnnouncements = async () => {
		getAllAnnouncements().then((ann) => setAnnouncements(ann.announcements))
	}

	const fetchFeedbacks = async () => {
		getFeedbacks().then((feed) => setFeeds(feed.feedbacks));
	}

	const viewAnnouncement = (id) => {
		for (let i in announcements) {
			if (announcements[i]._id == id) {
				setAnnouncement(announcements[i]);
				break;
			}
		}
	};

	useEffect(() => {
		try {
			fetchAnnouncements();
			fetchFeedbacks();
		} catch { }
	}, []); // Executed only once at the start

	const handleCountySelection = event => {
		setCounty(event.target.value);
	};

	const onSubmit = () => {
		if (title == "") {
			error("Ο τίτλος δεν μπορεί να είναι κενός");
			return;
		}
		if (description == "") {
			error("Η περιγραφή δεν μπορεί να είναι κενή");
			return;
		}

		let data = {
			title: title,
			body: description,
			image: imageUrl,
			url: url,
			issuer: "ΠΚΜ",
			counties: [county],
			expirationDate: expirationDate,
			pushNotification: pushNotification,
			sendTo: 0,
			readBy: 0,
		}
		newAnnouncement(data).then((res) => {
			if (res.status == 200) {
				info("Η αποστολή πραγματοποιήθηκε");
				setNewPopupOpen(false);
				fetchAnnouncements();
			}
			else {
				if (res.message.includes("image must be a valid URL"))
					error("Ο υπερσύνδεσμος εικόνας δεν είναι έγκυρος");
				else if (res.message.includes("url must be a valid URL"))
					error("Ο υπερσύνδεσμος ανακοίνωσης δεν είναι έγκυρος");
				else
					error(res.message);
			}
		})

	};
	const inputStyle = {
		color: "black",
		width: "100%",
		backgroundColor: "white",
		opacity: 0.7,
		borderRadius: "4px",
		margin: "0px",
		marginBottom: "10px",
	};

	const tableColumns = useMemo(() => [
		{
			Header: <Typography variant="h7">{"Δημιουργία"}</Typography>,
			accessor: "createdAt",
			id: "createdAt",
			minWidth: 50,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Typography sx={{ fontSize: 14 }}>{dayjs(value).format("L")}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography id="name_header" variant="h7">{"Τίτλος"}</Typography>,
			accessor: "title",
			id: "title",
			filterable: true,
			minWidth: 200,
			style: { whiteSpace: "unset", textAlign: "left" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Grid sx={{ display: "flex", ml: 1 }}>
					<Typography sx={{ fontSize: 14 }}>{value}</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography id="name_header" variant="h7">{"Αποστολές"}</Typography>,
			accessor: "sendTo",
			id: "sendTo",
			minWidth: 30,
			style: { whiteSpace: "unset", textAlign: "left" },
			Cell: ({ value }) => (
				<Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Typography sx={{ fontSize: 14 }}>{value}</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography id="name_header" variant="h7">{"Αναγνώσεις"}</Typography>,
			accessor: "readBy",
			id: "readBy",
			minWidth: 30,
			style: { whiteSpace: "unset", textAlign: "left" },
			Cell: ({ value }) => (
				<Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Typography sx={{ fontSize: 14 }}>{value}</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography variant="h7">{"Λήξη"}</Typography>,
			accessor: "expirationDate",
			id: "expirationDate",
			minWidth: 50,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			Cell: ({ value }) => (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography
						backgroundColor={dayjs(value).isSameOrAfter(new Date()) === true ? "success.main" : "third.main"}
						sx={{ fontSize: 14, padding: 1, borderRadius: 2 }}

					>{dayjs(value).format("L")}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography variant="h7">{"Δράσεις"}</Typography>,
			accessor: "_id",
			filterable: false,
			sortable: false,
			width: 100,
			Cell: ({ value }) => (
				<ToggleButtonGroup exclusive size="small" aria-label="actions">
					<ToggleButton
						value="view"
						title="view"
						aria-label="view"
						sx={{ borderColor: "primary.main" }}
						onClick={() => {
							viewAnnouncement(value);
							setViewPopupOpen(true);
						}}
					>
						<VisibilityIcon color="info" />
					</ToggleButton>
					<ToggleButton
						value="delete"
						title="delete"
						aria-label="delete"
						sx={{ borderColor: "primary.main" }}
						onClick={() => {
							deleteAnnouncement(value).then(() => {
								info("Η ανακοίνωση διαγράφηκε");
								fetchAnnouncements();
							});
						}}
					>
						<Delete color="error" />
					</ToggleButton>
				</ToggleButtonGroup >
			),
		},
	], [announcements]);

	const feedbackTableColumns = useMemo(() => [
		{
			Header: <Typography variant="h7">{"Αποστολή"}</Typography>,
			accessor: "createdAt",
			id: "createdAt",
			minWidth: 50,
			style: { overflow: "visible" },
			sortMethod: (value1, value2) => dateOldToNew((v) => new Date(v))(value1, value2),
			Cell: ({ value }) => (
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Typography sx={{ fontSize: 14 }}>{dayjs(value).format("L")}</Typography>
				</Box>
			),
		},
		{
			Header: <Typography id="name_header" variant="h7">{"Κατηγορία"}</Typography>,
			accessor: "category",
			id: "category",
			filterable: true,
			minWidth: 80,
			style: { whiteSpace: "unset", textAlign: "left" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Grid sx={{ display: "flex", ml: 1 }}>
					<Typography sx={{ fontSize: 14 }}>{value}</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography id="name_header" variant="h7">{"Πληροφορίες"}</Typography>,
			accessor: "specifics",
			id: "specifics",
			filterable: true,
			minWidth: 200,
			style: { whiteSpace: "unset", textAlign: "left" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Grid sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
					{Object.keys(JSON.parse(value)).map((key, index) => {
						return <Typography sx={{ fontSize: 14 }}>{`${key} : ${JSON.stringify(JSON.parse(value)[key])}`}</Typography>;
					})}
				</Grid>
			),
		},
		{
			Header: <Typography id="name_header" variant="h7">{"Σχόλιο"}</Typography>,
			accessor: "userComment",
			id: "userComment",
			filterable: true,
			minWidth: 100,
			style: { whiteSpace: "unset", textAlign: "left" },
			sortMethod: (value1, value2) => stringAToZInsensitive()(value1, value2),
			filterMethod: ({ id, value }, row) => isFuzzyMatch(row[id], value),
			Cell: ({ value }) => (
				<Grid sx={{ display: "flex", ml: 1 }}>
					<Typography sx={{ fontSize: 14 }}>{value}</Typography>
				</Grid>
			),
		},
		{
			Header: <Typography variant="h7">{"Ελέγχθηκε"}</Typography>,
			accessor: "resolved",
			filterable: false,
			sortable: false,
			width: 100,
			Cell: ({ value }) => (
				value == false ? <ReportIcon color="error" /> : <VerifiedIcon color="success" />
			),
		},
		{
			Header: <Typography variant="h7">{"Δράσεις"}</Typography>,
			accessor: "_id",
			filterable: false,
			sortable: false,
			width: 100,
			Cell: ({ value }) => (
				<ToggleButtonGroup exclusive size="small" aria-label="actions">
					<ToggleButton
						value="view"
						title="view"
						aria-label="view"
						sx={{ borderColor: "primary.main" }}
						onClick={() => {
							resolveFeedback(value).then(() => {
								info("Η κατάσταση του σχολίου ανανεώθηκε");
								fetchFeedbacks();
							});
						}}
					>
						<CheckIcon color="main" />

					</ToggleButton>
					<ToggleButton
						value="delete"
						title="delete"
						aria-label="delete"
						sx={{ borderColor: "primary.main" }}
						onClick={() => {
							deleteFeedback(value).then(() => {
								info("Το σχόλιο διαγράφηκε");
								fetchFeedbacks();
							});
						}}
					>
						<Delete color="error" />
					</ToggleButton>
				</ToggleButtonGroup >
			),
		},
	], [feeds]);

	return (
		<>
			{/* The viewing popup */}
			<Popup
				width="800px"
				title="Ανακοίνωση"
				open={viewPopupOpen}
				onClose={() => setViewPopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Grid container direction="column">
						<Typography variant="h7" sx={{ pb: 3 }}>
							{announcement?.title}
						</Typography>
						<Typography variant="body1" sx={{ pb: 3, fontSize: 14 }}>
							{announcement?.body}
						</Typography>
						<Typography variant="body1" sx={{ fontSize: 14 }}>
							{"Περιοχές: " + (announcement?.counties[0] == "*" ? "Όλες" : announcement?.counties[0])}
						</Typography>
						{(announcement?.image &&
							<Image
								src={`${announcement?.image}`}
								fit="scale-down"
								height="300px"
								style={{
									padding: 20
								}}
							/>
						)}
						{(announcement?.url &&
							<PrimaryBackgroundButton
								title="Aνακοίνωση"
								onClick={() => window.open(announcement?.url, '_blank', 'noopener,noreferrer')}
							/>
						)}
					</Grid>
				</Grid>
			</Popup>
			{/* The new announcement popup */}
			<Popup
				width="400px"
				title="Τίτλος"
				open={titlePopupOpen}
				onClose={() => setTitlePopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography>
						Ο Τίτλος της ανακοίνωσης πρέπει να είναι σύντομος και περιεκτικός. Π.χ.
						<br /><br />
						"Στηρίζουμε... Παίζοντας για καλό σκόπό"
					</Typography>
				</Grid>
			</Popup>
			<Popup
				width="600px"
				title="Περιγραφή"
				open={descriptionPopupOpen}
				onClose={() => setDescriptionPopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography>
						Η περιγραφή της ανακοίνωσης πρέπει να είναι σύντομη και περιεκτική, και πρότεινεται να μην ξεπερνά την μία παράγραφο. Οι πλήρεις πληροφορίες για την ανακοίνωση θα υπάρχουν στον υπερσύνδεσμο που θα δηλώσετε στην συνέχεια. Π.χ.
						<br /><br />
						Το 12o "Στηρίζουμε... Παίζοντας" έρχεται την Κυριακή 18/12/2022 από τις 11.00 έως τις 19.00 στο Δημαρχείο Θεσσαλονίκης (Αίθουσα Μανώλης Αναγνωστάκης). Ο σκοπός της εκδήλωσης είναι η συλλογή τροφίμων για τις άπορες οικογένειες που στηρίζει η ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ και πραγματοποιείται στο πλαίσιο των δραστηριοτήτων της ομάδας, με στόχο τόσο την ευαισθητοποίηση του κοινού όσο και την έμπρακτη συμμετοχή του σε θέματα εθελοντισμού και αλληλεγγύης. Για τον λόγο αυτόν, αντί εισιτηρίου, συλλέγουμε τρόφιμα μακράς διάρκειας, χαρτικά, απορρυπαντικά και είδη προσωπικής υγιεινής. Η εκδήλωση πραγματοποιείται σε συνδιοργάνωση και υπό την αιγίδα της Περιφέρειας Κεντρικής Μακεδονίας και του Κέντρου Πολιτισμού Π.Κ.Μ καθώς επίσης και του Δήμου Θεσσαλονίκης.
					</Typography>
				</Grid>
			</Popup>
			<Popup
				width="600px"
				title="Υπερσύνδεσμος εικόνας"
				open={imagePopupOpen}
				onClose={() => setImagePopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography>
						Εισάγετε τον υπερσύνδεσμο μίας εικόνας σχετικής με την ανακοίνωση Π.χ.
						<br /><br />
						https://www.gothess.gr/_modfoto/articlesx/20221211-001415-bCB2/stirizoume-paizontas-sto-dimarxeio-thessalonikis.jpg
						<br /><br />
						Η εικόνα θα εμφανιστεί με τον εξής τρόπο:
					</Typography>

				</Grid>
				<Image
					src={`https://www.gothess.gr/_modfoto/articlesx/20221211-001415-bCB2/stirizoume-paizontas-sto-dimarxeio-thessalonikis.jpg`}
					fit="scale-down"
					height="300px"
					style={{
						padding: 20
					}}
				/>
			</Popup>
			<Popup
				width="600px"
				title="Υπερσύνδεσμος ανακοίνωσης"
				open={urlPopupOpen}
				onClose={() => setUrlPopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography>
						Εισάγετε τον υπερσύνδεσμο της ανακοίνωσης, στον οποίο υπάρχουν τα πλήρη της στοιχεία Π.χ.
						<br /><br />
						https://www.pkm.gov.gr/default.aspx?lang=el-GR&page=35&pressid=24794
						<br /><br />
						Ο υπερσύνδεσμος θα εμφανιστεί με τον εξής τρόπο:
						<br /><br />
					</Typography>
				</Grid>
				<PrimaryBackgroundButton
					title="Aνακοίνωση"
					onClick={() => window.open("https://www.pkm.gov.gr/default.aspx?lang=el-GR&page=35&pressid=24794", '_blank', 'noopener,noreferrer')}
				/>
			</Popup>
			<Popup
				width="400px"
				title="Σχετικές περιοχές"
				open={countiesPopupOpen}
				onClose={() => setCountiesPopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography>
						Επιλέξτε την περιοχή που αφορά η ανακοίνωση, αφού αυτή θα γίνει εμφανής μόνο στους χρήστες που έχουν δηλώσει την συγκεκριμένη περιοχή.
					</Typography>
				</Grid>
			</Popup>
			<Popup
				width="400px"
				title="Σχετικές περιοχές"
				open={pushPopupOpen}
				onClose={() => setPushPopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography>
						Επιλέξτε την αποστολή άμεσης ειδοποίησης εάν επιθυμείτε να στείλετε push notification (ειδοποίηση στο κινητό τηλέφωνο) στους χρήστες της εφαρμογής. Η συγκεκριμένη λειτουργία προτείνεται να χρησιμοποιείται με φειδώ.
					</Typography>
				</Grid>
			</Popup>
			<Popup
				width="400px"
				title="Ημερομηνία λήξης"
				open={datePopupOpen}
				onClose={() => setDatePopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography>
						Επιλέξτε την ημερομηνία, μετά την οποία η ανακοίνωση δεν έχει νόημα. Για παράδειγμα, για την εκδήλωση Στηρίζουμε... Παίζοντας, η ημερομηνία μπορεί να τεθεί 19/12/2022, αφού η εκδήλωση θα πραγματοποιηθεί στις 18/12/2022. Στους χρήστες θα εμφανίζονται μόνο ανακοινώσεις με ημερομηνία λήξης μεγαλύτερη από την τρέχουσα.
					</Typography>
				</Grid>
			</Popup>

			<Popup
				width="800px"
				title="Νέα ανακοίνωση"
				open={newPopupOpen}
				onClose={() => setNewPopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Grid item width="800px">
						<Grid item width="100%" display="flex" flexDirection="row">
							<ToggleButton
								sx={{ mb: "10px" }}
								onClick={() => setTitlePopupOpen(true)}
							>
								<Info color="primary.main" />
							</ToggleButton>
							<TextField
								id="filled-basic"
								label="Τίτλος *"
								variant="filled"
								style={inputStyle}
								onChange={(e) => setTitle(e.target.value)}
							/>
						</Grid>
						<Grid item width="100%" display="flex" flexDirection="row">
							<ToggleButton
								sx={{ mb: "10px" }}
								onClick={() => setDescriptionPopupOpen(true)}
							>
								<Info color="primary.main" />
							</ToggleButton>
							<TextField
								id="filled-basic"
								label="Περιγραφή *"
								variant="filled"
								multiline
								maxRows={10}
								style={inputStyle}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</Grid>
						<Grid item width="100%" display="flex" flexDirection="row">
							<ToggleButton
								sx={{ mb: "10px" }}
								onClick={() => setImagePopupOpen(true)}
							>
								<Info color="primary.main" />
							</ToggleButton>
							<TextField
								id="filled-basic"
								label="Υπερσύνδεσμος εικόνας"
								variant="filled"
								multiline
								maxRows={10}
								style={inputStyle}
								onChange={(e) => setImageUrl(e.target.value)}
							/>
						</Grid>
						<Grid item width="100%" display="flex" flexDirection="row">
							<ToggleButton
								sx={{ mb: "10px" }}
								onClick={() => setUrlPopupOpen(true)}
							>
								<Info color="primary.main" />
							</ToggleButton>
							<TextField
								id="filled-basic"
								label="Υπερσύνδεσμος ανακοίνωσης"
								variant="filled"
								multiline
								maxRows={10}
								style={inputStyle}
								onChange={(e) => setUrl(e.target.value)}
							/>
						</Grid>
						<Grid item width="100%" display="flex" flexDirection="row">
							<ToggleButton
								onClick={() => setCountiesPopupOpen(true)}
							>
								<Info color="primary.main" />
							</ToggleButton>
							{/* Counties */}
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Σχετικές περιοχές</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="filled-basic"
									value={county}
									label="Σχετικές περιοχές"
									onChange={handleCountySelection}
								>
									{counties.map(item => (
										<MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item width="100%" display="flex" flexDirection="row" alignItems="center" sx={{ mt: "10px", mb: "10px" }}>
							<ToggleButton
								onClick={() => setPushPopupOpen(true)}
							>
								<Info color="primary.main" />
							</ToggleButton>
							{/* Push notification checkbox */}
							<FormControlLabel
								control={<Checkbox />}
								label="Αποστολή άμεσης ειδοποίησης"
								style={{
									color: "black",
									width: "100%",
									backgroundColor: "white",
									opacity: 0.7,
									borderRadius: "4px",
									margin: "0px",
									// 
								}}
								onChange={(e) => {
									setPushNotification(e.target.checked);
								}}
							/>
						</Grid>
						<Grid item width="100%" display="flex" flexDirection="row" alignItems="center" sx={{ mt: "10px", mb: "10px" }}>
							<ToggleButton
								onClick={() => setDatePopupOpen(true)}
							>
								<Info color="primary.main" />
							</ToggleButton>
							{/* Expiration date picker */}
							<Grid container
								display="flex"
								alignItems="center"
								style={{
									color: "black",
									width: "100%",
									backgroundColor: "white",
									opacity: 0.7,
									borderRadius: "4px",
									margin: "0px",
									paddingLeft: "10px"
								}}
							>
								<Grid item xs={3}>
									<Typography>{"Ημερομηνία λήξης"}</Typography>
								</Grid>
								<Grid item xs={4}>
									<DatePicker
										selected={expirationDate}
										onChange={(date) => setExpirationDate(date)}
									/>
								</Grid>
							</Grid>
						</Grid>
						{/* Submission button */}
						<Grid container justifyContent="flex-end">
							<SecondaryBackgroundButton
								title="Αποστολή"
								onClick={() => onSubmit()}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Popup>
			{/* The table */}
			<Grid container direction="column" alignItems="center" justifyContent="center">
				<Grid container>
					<Grid item xs>
						<Grid container justifyContent="flex-start" sx={{ pt: 3 }}>
							<Typography color={"#fff"} variant={"h5"}>{"Διαχείριση ανακοινώσεων"}</Typography>
						</Grid>
						<Grid container justifyContent="flex-end" sx={{ pb: 3, pt: 3 }}>
							<SecondaryBackgroundButton
								title="Νέα ανακοίνωση"
								onClick={() => {
									setNewPopupOpen(true);
									setTitle("");
									setDescription("");
									setImageUrl("");
									setUrl("");
									setPushNotification(false);
								}}
							/>
						</Grid>
						<Table
							data={announcements}
							noDataText={isLoading ? "Fetching data..." : "No data available!"}
							columns={tableColumns}
							defaultSorted={[{ id: "title", desc: true }]}
							style={{ width: "100%" }}
						/>
					</Grid>
				</Grid>
			</Grid>
			{/* The feedback table */}
			<Grid container direction="column" alignItems="center" justifyContent="center">
				<Grid container>
					<Grid container justifyContent="flex-start" sx={{ pb: 3, pt: 5 }}>
						<Typography color={"#fff"} variant={"h5"}>{"Διαχείριση σχολίων χρηστών"}</Typography>
					</Grid>
					<Grid item xs>
						<Table
							data={feeds}
							noDataText={isLoading ? "Fetching data..." : "No data available!"}
							columns={feedbackTableColumns}
							defaultSorted={[{ id: "title", desc: true }]}
							style={{ width: "100%", paddingBottom: "30px" }}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(Home);
