// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "#00534b",
	"secondary": "#04598c",
	"third": "#787d7d",
	"success": "#9BCB3F",
	"error": "#EF4930",
	"warning": "#FAB325",
	"info": "#006BA4",
	"dark": "#262835",
	"light": "#F1A542",
	"grey": "#DBDFE1",
	"greyDark": "#BAC1C5",
	"green": "#51BEA5"
};
export default ___CSS_LOADER_EXPORT___;
