const counties = [
    { label: 'Όλες', value: '*', valueForDoctors: '' },
    { label: 'ΑΙΤΩΛΟΑΚΑΡΝΑΝΙΑΣ', value: 'Αιτωλοακαρνανία', valueForDoctors: 'Αιτωλοακαρνανίας' },
    { label: 'ΑΡΓΟΛΙΔΑΣ', value: 'Αργολίδα', valueForDoctors: 'Αργολίδας' },
    { label: 'ΑΡΚΑΔΙΑΣ', value: 'Αρκαδία', valueForDoctors: 'Αρκαδίας' },
    { label: 'ΑΡΤΑΣ', value: 'Άρτα', valueForDoctors: 'Άρτας' },
    { label: 'ΑΤΤΙΚΗΣ', value: 'Αττική', valueForDoctors: 'Αττικής' },
    { label: 'ΑΧΑΙΑΣ', value: 'Αχαΐα', valueForDoctors: 'Αχαΐας' },
    { label: 'ΒΟΙΩΤΙΑΣ', value: 'Βοιωτία', valueForDoctors: 'Βοιωτίας' },
    { label: 'ΓΡΕΒΕΝΩΝ', value: 'Γρεβενά', valueForDoctors: 'Γρεβενών' },
    { label: 'ΔΡΑΜΑΣ', value: 'Δράμα', valueForDoctors: 'Δράμας' },
    { label: 'ΔΩΔΕΚΑΝΗΣΣΟΥ', value: 'Δωδεκάνησσος', valueForDoctors: 'Δωδεκανήσου' },
    { label: 'ΕΒΡΟΥ', value: 'Έβρος', valueForDoctors: 'Έβρου' },
    { label: 'ΕΥΒΟΙΑΣ', value: 'Εύβοια', valueForDoctors: 'Ευβοίας' },
    { label: 'ΕΥΡΥΤΑΝΙΑΣ', value: 'Ευρυτανία', valueForDoctors: 'Ευρυτανία' },
    { label: 'ΖΑΚΥΝΘΟΥ', value: 'Ζάκυνθος', valueForDoctors: 'Ζακύνθου' },
    { label: 'ΗΛΕΙΑΣ', value: 'Ηλεία', valueForDoctors: 'Ηλείας' },
    { label: 'ΗΜΑΘΙΑΣ', value: 'Ημαθία', valueForDoctors: 'Ημαθίας' },
    { label: 'ΗΡΑΚΛΕΙΟΥ', value: 'Ηράκλειο', valueForDoctors: 'Ηρακλείου' },
    { label: 'ΘΕΣΠΡΩΤΙΑΣ', value: 'Θεσπρωτία', valueForDoctors: 'Θεσπρωτίας' },
    { label: 'ΘΕΣΣΑΛΟΝΙΚΗΣ', value: 'Θεσσαλονίκη', valueForDoctors: 'Θεσσαλονίκης' },
    { label: 'ΙΩΑΝΝΙΝΩΝ', value: 'Ιωάννινα', valueForDoctors: 'Ιωαννίνων' },
    { label: 'ΚΑΒΑΛΑΣ', value: 'Καβάλα', valueForDoctors: 'Καβάλας' },
    { label: 'ΚΑΡΔΙΤΣΑΣ', value: 'Καρδίτσα', valueForDoctors: 'Καρδίτσας' },
    { label: 'ΚΑΣΤΟΡΙΑ', value: 'Καστοριά', valueForDoctors: 'Καστοριάς' },
    { label: 'ΚΕΡΚΥΡΑΣ', value: 'Κέρκυρα', valueForDoctors: 'Κερκύρας' },
    { label: 'ΚΕΦΑΛΗΝΙΑΣ', value: 'Κεφαληνία', valueForDoctors: 'Κεφαλληνίας' },
    { label: 'ΚΙΛΚΙΣ', value: 'Κιλκίς', valueForDoctors: 'Κιλκίς' },
    { label: 'ΚΟΖΑΝΗΣ', value: 'Κοζάνη', valueForDoctors: 'Κοζάνης' },
    { label: 'ΚΟΡΙΝΘΟΥ', value: 'Κόρινθος', valueForDoctors: 'Κορινθίας' },
    { label: 'ΚΥΚΛΑΔΕΣ', value: 'Κυκλάδες', valueForDoctors: 'Κυκλάδων' },
    { label: 'ΛΑΚΩΝΙΑΣ', value: 'Λακωνία', valueForDoctors: 'Λακωνίας' },
    { label: 'ΛΑΡΙΣΗΣ', value: 'Λάρισα', valueForDoctors: 'Λάρισας' },
    { label: 'ΛΑΣΙΘΙΟΥ', value: 'Λασίθη', valueForDoctors: 'Λασιθίου' },
    { label: 'ΛΕΣΒΟΥ', value: 'Λέσβος', valueForDoctors: 'Λέσβου' },
    { label: 'ΛΕΥΚΑΔΑΣ', value: 'Λευκάδα', valueForDoctors: 'Λευκάδας' },
    { label: 'ΜΑΓΝΗΣΙΑΣ', value: 'Μαγνησία', valueForDoctors: 'Μαγνησίας' },
    { label: 'ΜΕΣΣΗΝΙΑΣ', value: 'Μεσσηνία', valueForDoctors: 'Μεσσηνίας' },
    { label: 'ΞΑΝΘΗΣ', value: 'Ξάνθη', valueForDoctors: 'Ξάνθης' },
    { label: 'ΠΕΛΛΗΣ', value: 'Πέλλα', valueForDoctors: 'Πέλλας' },
    { label: 'ΠΙΕΡΙΑΣ', value: 'Πιερία', valueForDoctors: 'Πιερίας' },
    { label: 'ΠΡΕΒΕΖΗΣ', value: 'Πρέβέζα', valueForDoctors: 'Πρέβέζας' },
    { label: 'ΡΕΘΥΜΝΗΣ', value: 'Ρέθυμνο', valueForDoctors: 'Ρεθύμνης' },
    { label: 'ΡΟΔΟΠΗΣ', value: 'Ροδόπη', valueForDoctors: 'Ροδόπης' },
    { label: 'ΣΑΜΟΥ', value: 'Σάμος', valueForDoctors: 'Σάμου' },
    { label: 'ΣΕΡΡΩΝ', value: 'Σέρρες', valueForDoctors: 'Σερρών' },
    { label: 'ΤΡΙΚΑΛΩΝ', value: 'Τρίκαλα', valueForDoctors: 'Τρικάλων' },
    { label: 'ΦΘΙΩΤΙΔΟΣ', value: 'Φθιώτιδα', valueForDoctors: 'Φθιώτιδας' },
    { label: 'ΦΛΩΡΙΝΑΣ', value: 'Φλώρινα', valueForDoctors: 'Φλώρινας' },
    { label: 'ΦΩΚΙΔΟΣ', value: 'Φωκίδα', valueForDoctors: 'Φωκίδας' },
    { label: 'ΧΑΛΚΙΔΙΚΗΣ', value: 'Χαλκιδική', valueForDoctors: 'Χαλκιδικής' },
    { label: 'ΧΑΝΙΩΝ', value: 'Χανιά', valueForDoctors: 'Χανίων' },
    { label: 'ΧΙΟΥ', value: 'Χίος', valueForDoctors: 'Χίου' },
  ];

  export default counties;