import { Grid, InputAdornment } from "@mui/material";
import { memo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Form from "../components/Form";
import { useSnackbar } from "../utils";

const FormExample = () => {
	const { info } = useSnackbar();
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

    const onSubmit = (values) => {
        console.log(values);
        info("Form submitted");
    };

	const formContent = [
		{
			customType: "input",
			id: "username",
			type: "text",
			placeholder: "Username",
			inputProps: {
				endAdornment: (
					<InputAdornment position="start">
						<IconButton disabled>
							<AccountCircle />
						</IconButton>
					</InputAdornment>
				),
			}
		},
		{
			customType: "input",
			id: "password",
			type: showPassword ? "text" : "password",
			placeholder: "Password",
			inputProps: {
				endAdornment: (
					<InputAdornment position="start">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleShowPassword}
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				),
			}
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Submit"
		}
	];

	return (
		<Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
            <Grid item width="500px">
                <Form content={formContent} validationSchema="authenticationSchema" onSubmit={onSubmit} />
            </Grid>
		</Grid>
	);
};

export default memo(FormExample);
