import { Grid } from "@mui/material";
import { memo, useState } from "react";
import Dropdown from "../components/Dropdown";

const DropdownExample = () => {
	const [value, setValue] = useState("");

	const items = [
		{
			value: 10,
			text: "Ten"
		},
		{
			value: 20,
			text: "Twenty"
		},
		{
			value: 30,
			text: "Thirty"
		},
	];

	const onChange = (event) => setValue(event.target.value);

	return (
		<Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
			<Grid item>
				<Dropdown
					id="example-dropdown"
					items={items}
					value={value}
					onChange={onChange}
					size="medium"
					width="200px"
					filled={false}
					background="primary"
				/>
			</Grid>
			<Grid item mt={2}>
				<Dropdown
					id="example-dropdown"
					items={items}
					value={value}
					onChange={onChange}
					size="medium"
					width="200px"
					filled={true}
					background="primary"
				/>
			</Grid>
		    <Grid item mt={2}>
			    <Dropdown
					id="example-dropdown"
					items={items}
					value={value}
					onChange={onChange}
					size="medium"
					width="200px"
					filled={false}
					background="secondary"
				/>
		    </Grid>
			<Grid item mt={2}>
			    <Dropdown
					id="example-dropdown"
					items={items}
					value={value}
					onChange={onChange}
					size="medium"
					width="200px"
					filled={true}
					background="secondary"
				/>
		    </Grid>
		    <Grid item mt={2}>
			    <Dropdown
					id="example-dropdown"
					items={items}
					value={value}
					onChange={onChange}
					size="medium"
					width="200px"
					filled={false}
					background="third"
				/>
		    </Grid>
			<Grid item mt={2}>
			    <Dropdown
					id="example-dropdown"
					items={items}
					value={value}
					onChange={onChange}
					size="medium"
					width="200px"
					filled={true}
					background="third"
				/>
		    </Grid>
		</Grid>
	);
};

export default memo(DropdownExample);
